// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-account-js": () => import("../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-admin-js": () => import("../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-dash-js": () => import("../src/pages/dash.js" /* webpackChunkName: "component---src-pages-dash-js" */),
  "component---src-pages-features-js": () => import("../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-home-js": () => import("../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plans-js": () => import("../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-pw-forget-js": () => import("../src/pages/pw-forget.js" /* webpackChunkName: "component---src-pages-pw-forget-js" */),
  "component---src-pages-signin-js": () => import("../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */)
}

